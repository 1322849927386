import React from "react";

const PartnersCard = ({ Image, Price, ParnetrName, PartnerText, PartnerBodyText }) => {
    return (

        <div className="flex max-w-[32.8%] max-md:max-w-[49%] max-sm:max-w-full w-full px-4 py-2 rounded-xl  flex-col-3 bg-[#fafafa]">
            <div className="flex max-sm:gap-3 items-center justify-between w-full min-h-[120px]">
                <div
                    className="w-[100px] h-[100px] max-sm:w-[80px] max-sm:h-[80px] rounded-xl overflow-hidden flex  items-center justify-center "
                >
                    <img
                        alt="Araz Market"
                        className="object-cover w-full h-full"
                        src={Image}
                    />
                </div>
                <div>
                    <div className="flex flex-col   w-32 ">
                        <h2
                            style={{
                                fontWeight: 200,
                                fontFamily: "OpenSans-Regular",
                                fontSize: "16px",

                            }}
                            className=" text-gray-800 text-left  ">{
                                ParnetrName
                            }</h2>
                        <p
                            style={{
                                fontWeight: 200,
                                fontFamily: "OpenSans-Regular",
                                fontSize: "14px",

                            }}
                            className="text-gray-400  text-sm text-left"
                        >
                            {PartnerText}
                        </p>
                    </div>
                    <p
                        className="text-gray-400  text-sm mt-1"
                        style={{
                            fontWeight: 100,
                            fontFamily: "OpenSans-Regular",
                            fontSize: "11px",
                        }}

                    >
                        {PartnerBodyText}
                    </p>
                </div>
                <div>
                    <h2
                        className="text-[#00a650] text-4xl max-sm:text-2xl font-bold"
                    >
                        {Price} %
                    </h2>
                </div>
            </div>

        </div>


    );
};

export default PartnersCard;
